<template>
  <div
    :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
    class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
          title
        }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>

      <!--begin::Input-->
      <div class="align-items-end flex-column">
        <Field
          class="form-control form-control-lg"
          type="search"
          name="keyword"
          placeholder="Search..."
          autocomplete="off"
          v-model="filters.keyword"
          @input="updatePartsFilterKeyword"
        />
      </div>
      <!--end::Input-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="border-0">
              <th class="min-w-100px">MA ID</th>
              <th class="min-w-110px">OEM ID</th>
              <!--th>Vendor ID</th>-->
              <th>Client ID</th>
              <!--<th style="w-50px"></th>-->
              <th>OEM name</th>
              <!--<th>Vendor name</th>-->
              <th>Description</th>
              <th style="min-width: 170px">Location</th>
              <th class="w-50px">Current stock</th>
              <th class="w-50px" v-if="asset == 'All'">LTA</th>
              <th class="w-50px">
                <el-tooltip content="Possible (P10): The lowest figure, it indicates the minimum required stock value" placement="left">
                  <span>P10</span>
                </el-tooltip>
              </th>
              <th class="w-50px">
                <el-tooltip content="Median (P50): This is the median figure, it is the best estimate from maintenance team" placement="left">
                  <span>P50</span>
                </el-tooltip>
              </th>
              <th class="w-50px">
                <el-tooltip content="Proved (P90): The highest figure, it indicates the maximum required stock value" placement="left">
                  <span>P90</span>
                </el-tooltip>
              </th>
              <th class="w-50px">Price</th>
              <th class="text-end">Status</th>
            </tr>
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody v-if="dataLoaded">
            <template v-for="(item, index) in visibleItems" :key="index">
              <tr>
                <td class="text-start">
                  <span class="fw-bold d-block fs-7">{{ item.predsolID }}</span>
                </td>
                <td class="text-start">
                  <span class="fw-bold d-block fs-7">{{ item.oemID }}</span>
                </td>
                <!--<td class="text-start">
                  <span class="fw-bold d-block fs-7">{{ item.vendorID }}</span>
                </td>-->
                <td class="text-start">
                  <span
                    v-if="item.clientID.length > 0"
                    class="fw-bold d-block fs-5"
                    >{{ item.clientID }}</span
                  >
                  <!--<VueMultiselect
                    v-if="item.clientID.length < 3"
                    :key="`${item.id}`"
                    :options="socarCapeOptions"
                    @search-change="asyncFind"
                    :custom-label="socarCapePartLabel"
                    placeholder="Select part"
                    label="scID"
                    track-by="scID"
                    :searchable="true"
                    :multiple="false"
                    :close-on-select="true"
                    @close="stopSCselect"
                    @open="startSCselect(item.id)"
                    @update:model-value="updateSelectedSCoption"
                    v-model="
                      $data[
                        selectedSCoption[item.id]
                          ? selectedSCoption[item.id]
                          : item.clientID
                      ]
                    "
                  ></VueMultiselect>-->
                  <!--end::Select-->
                </td>
                <!--<td class="text-start">
                  <button
                    type="button"
                    class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary ms-2"
                    @click="savePartClientID(item.id)"
                    v-if="selectedSCoption[item.id] && item.clientID.length < 3"
                  >
                    <span class="svg-icon svg-icon-2">
                      <inline-svg src="media/icons/duotone/General/Save.svg" />
                    </span>
                  </button>
                </td>-->
                <td class="text-start">
                  <span class="fw-bold d-block fs-7">{{ item.oemName }}</span>
                </td>
                <!--<td class="text-start">
                  <span class="fw-bold d-block fs-7">{{
                      item.vendorName
                    }}</span>
                </td>-->
                <td class="text-start">
                  <span class="fw-bold d-block fs-7">{{
                    item.description
                  }}</span
                  ><br />
                  <em class="d-block fs-7">{{ item.comments }}</em>
                </td>
                <td class="text-start">
                  <div class="symbol symbol-45px me-1">
                    <span
                      class="bg-primary symbol-label text-light flex-column fw-bold fs-2 pt-1"
                    >
                      <span class="fs-8 text-light flex-row">Shelf</span>
                      {{ item.shelf }}
                    </span>
                  </div>

                  <div class="symbol symbol-45px me-1">
                    <span
                      class="bg-primary symbol-label text-light flex-column fw-bold fs-2 pt-1"
                    >
                      <span class="fs-8 text-light flex-row">Row</span>
                      {{ item.row }}
                    </span>
                  </div>

                  <div class="symbol symbol-45px">
                    <span
                      class="bg-primary symbol-label text-light flex-column fw-bold fs-2 pt-1"
                    >
                      <span class="fs-8 text-light flex-row">Bin</span>
                      {{ item.bin }}
                    </span>
                  </div>
                </td>
                <td class="text-start">
                  <span class="fw-bold d-block fs-7"  v-if="asset !== 'All'">{{
                    item.currentStock
                  }}</span>

                  <Field
                    v-if="asset == 'All'"
                    style="text-align: center;"
                    class="form-control"
                    type="text"
                    name="itemCurrentStock"
                    value="item.currentStock"
                    autocomplete="off"
                    v-model="item.currentStock"
                    :partID="item.id"
                    @input="updatePartCurrentStock"
                  />
                </td>
                <td class="text-start" v-if="asset == 'All'">
                  <span
                    v-if="item.lta == 'Yes'"
                    class="badge badge-light-success fw-bold d-block fs-7"
                    >{{ item.lta }} ({{ item.lta_count }})</span
                  >
                  <span
                    v-if="item.lta == 'No'"
                    class="badge badge-light-info fw-bold d-block fs-7"
                    >{{ item.lta }}</span
                  >
                  <span
                    v-if="item.lta == 'N/A'"
                    class="badge badge-light-danger fw-bold d-block fs-7"
                    >{{ item.lta }}</span
                  >
                </td>
                <td class="text-start">
                  <span class="badge fw-bold d-block fs-7" :class="{ 'badge-light-danger': item.currentStock < item.p10, 'badge-light-success': item.currentStock > item.p10, 'badge-light-info': item.currentStock == item.p10 }" v-if="asset === 'All'">{{
                    item.p10
                  }}</span>
                  <Field
                      v-if="asset !== 'All'"
                      style="text-align: center;"
                      class="form-control badge px-0 py-1"
                      :class="{ 'badge-light-danger': item.currentStock < item.p10, 'badge-light-success': item.currentStock > item.p10, 'badge-light-info': item.currentStock == item.p10 }"
                      type="text"
                      name="itemP10"
                      value="item.p10"
                      autocomplete="off"
                      v-model="item.p10"
                      :partID="item.id"
                      @input="updatePartP10"
                  />
                </td>
                <td class="text-start">
                  <span class="badge fw-bold d-block fs-7" :class="{ 'badge-light-danger': item.currentStock < item.p50, 'badge-light-success': item.currentStock > item.p50, 'badge-light-info': item.currentStock == item.p50 }" v-if="asset !== 'All'">{{
                      item.p50
                    }}</span>

                  <Field
                    v-if="asset == 'All'"
                    style="text-align: center;"
                    class="form-control badge px-0 py-1"
                    :class="{ 'badge-light-danger': item.currentStock < item.p50, 'badge-light-success': item.currentStock > item.p50, 'badge-light-info': item.currentStock == item.p50 }"
                    type="text"
                    name="itemP50"
                    value="item.p50"
                    autocomplete="off"
                    v-model="item.p50"
                    :partID="item.id"
                    @input="updatePartP50"
                  />
                </td>
                <td class="text-start">
                  <span class="badge fw-bold d-block fs-7" :class="{ 'badge-light-danger': item.currentStock < item.p90, 'badge-light-success': item.currentStock > item.p90, 'badge-light-info': item.currentStock == item.p90 }" v-if="asset === 'All'">{{
                      item.p90
                    }}</span>
                  <Field
                      v-if="asset !== 'All'"
                      style="text-align: center;"
                      class="form-control badge px-0 py-1"
                      :class="{ 'badge-light-danger': item.currentStock < item.p90, 'badge-light-success': item.currentStock > item.p90, 'badge-light-info': item.currentStock == item.p90 }"
                      type="text"
                      name="itemP90"
                      value="item.p90"
                      autocomplete="off"
                      v-model="item.p90"
                      :partID="item.id"
                      @input="updatePartP90"
                  />
                </td>
                <td class="text-start">
                  <span class="fw-bold d-block fs-7">{{ item.price }}</span>
                </td>
                <td class="text-end">
                  <span
                    class="badge fw-bolder my-2"
                    :class="`badge-light-${item.badge.color}`"
                    >{{ item.badge.label }}</span
                  >
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>

        <div class="card-footer pb-0 pt-3 pe-0 text-right">
          <el-pagination
            v-if="dataLoaded"
            background
            @size-change="onSizeChange"
            @current-change="onPageChange"
            layout="prev, pager, next"
            :page-size="itemsPerPage"
            :total="totalItems"
          >
          </el-pagination>
          <!--<jw-pagination :items="items" pageSize="15" maxPages="5" @changePage="onPageChange"></jw-pagination>-->
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style>
.badge:empty {
  display: inline-block !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding-left: 1rem;
  min-width: 40px !important;
  height: 38px !important;
  line-height: 38px !important;
  background: transparent !important;
  border-radius: 6px !important;
}

.el-pagination.is-background .btn-next:hover,
.el-pagination.is-background .btn-prev:hover {
  color: #009ef7 !important;
  background-color: #f1faff !important;
}

.el-pager li {
  font-weight: 500;
  border-radius: 6px !important;
  min-width: 40px !important;
  height: 38px !important;
  line-height: 38px !important;
  background: transparent !important;
}

.el-pager li.active {
  font-weight: 700;
  color: #fff !important;
  background-color: #1b9931 !important;
}

.el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  line-height: 38px !important;
}

.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #009ef7 !important;
  background-color: #f1faff !important;
}

.text-right {
  text-align: right;
}

/*
.multiselect * {
  font-size: 12px !important;
}

.multiselect__content-wrapper {
  width: auto !important;
}
*/
</style>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { Field } from "vee-validate";
//import VueMultiselect from "vue-multiselect";
//import "vue-multiselect/dist/vue-multiselect.css";
//import JwPagination from 'jw-vue-pagination';

export default defineComponent({
  name: "PartsList",
  components: { Field }, //VueMultiselect
  props: {
    widgetClasses: String,
    asset: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const items = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const filteredItems = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const visibleItems = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const filters = { keyword: "" };
    const selectedSCoption: Array<string> = [];
    const currentlySelectable = 0;
    const socarCapeList = [];
    const socarCapeOptions = [];
    const title = "Parts list";
    const description = "List of all parts required by units";
    const dataLoaded = false;
    const totalItems = 0;
    const itemsPerPage = 10;
    const currentPage = 1;
    return {
      items,
      title,
      dataLoaded,
      filters,
      selectedSCoption,
      currentlySelectable,
      socarCapeOptions,
      socarCapeList,
      description,
      totalItems,
      currentPage,
      itemsPerPage,
      visibleItems,
      filteredItems
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  methods: {
    updatePartCurrentStock: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        newCurrentStock: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/update-current-stock",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW, and already updated in this table
            //this.loadAJAXData();
            const updateableItem = this.items.find(
              item => item.id === input.target.getAttribute("partID")
            );
            if (updateableItem) {
              updateableItem.currentStock = input.target.value;
              //console.log(updateableItem);
              this.updateVisibleItems();
            }
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    updatePartP10: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        assetID: this.asset,
        newP10: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
          "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p10",
          { data: updateData }
      )
          .then(data => {
            if (data.data.data === "ok") {
              //updated in PW, and already updated in this table
              //this.loadAJAXData();
              const updateableItem = this.items.find(
                  item => item.id === input.target.getAttribute("partID")
              );
              if (updateableItem) {
                updateableItem.p10 = input.target.value;
                //console.log(updateableItem);
                this.updateVisibleItems();
              }
            }
          })
          .catch(response => {
            //console.log(response);
          });
    },
    updatePartP50: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        newP50: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p50",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW, and already updated in this table
            //this.loadAJAXData();
            const updateableItem = this.items.find(
              item => item.id === input.target.getAttribute("partID")
            );
            if (updateableItem) {
              updateableItem.p50 = input.target.value;
              //console.log(updateableItem);
              this.updateVisibleItems();
            }
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    updatePartP90: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        assetID: this.asset,
        newP90: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
          "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p90",
          { data: updateData }
      )
          .then(data => {
            if (data.data.data === "ok") {
              //updated in PW, and already updated in this table
              //this.loadAJAXData();
              const updateableItem = this.items.find(
                  item => item.id === input.target.getAttribute("partID")
              );
              if (updateableItem) {
                updateableItem.p90 = input.target.value;
                //console.log(updateableItem);
                this.updateVisibleItems();
              }
            }
          })
          .catch(response => {
            //console.log(response);
          });
    },
    onPageChange: function(currentPage) {
      //this.pageOfItems = pageOfItems;
      //console.log("current page", currentPage);
      this.currentPage = currentPage;
      this.updateVisibleItems();
    },
    onSizeChange: function(itemsPerPage) {
      //console.log("items per page", itemsPerPage);
    },
    updatePartsFilterKeyword: function(selected) {
      this.filters.keyword = selected.target.value;
      this.filteredItems = this.items.filter(
        item =>
          item.predsolID.match(new RegExp(this.filters.keyword, "i")) ||
          item.oemID.match(new RegExp(this.filters.keyword, "i")) ||
          item.clientID.match(new RegExp(this.filters.keyword, "i")) ||
          item.oemName.match(new RegExp(this.filters.keyword, "i")) ||
          item.description.match(new RegExp(this.filters.keyword, "i"))
      );
      this.updateVisibleItems();
    },
    updateVisibleItems: function() {
      //v-if="(filters.keyword === '' || )"
      if (this.filters.keyword === "") {
        this.totalItems = this.items.length;
        this.visibleItems = this.items.slice(
          (this.currentPage - 1) * this.itemsPerPage,
          this.currentPage * this.itemsPerPage
        );
      } else {
        this.totalItems = this.filteredItems.length;
        this.visibleItems = this.filteredItems.slice(
          (this.currentPage - 1) * this.itemsPerPage,
          this.currentPage * this.itemsPerPage
        );
      }
    },
    savePartClientID(id) {
      const updateData = { partID: id, newClientID: this.selectedSCoption[id] };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/socarcape/update",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW
            this.loadAJAXData();
            // const updateableItem = this.items.find((item: Object) => item.id === id);
            //updateableItem.clientID = this.selectedSCoption[id];
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    startSCselect(id) {
      this.currentlySelectable = id;
    },
    stopSCselect() {
      this.currentlySelectable = 0;
      this.socarCapeOptions = [];
    },
    updateSelectedSCoption(newSelected) {
      if (this.currentlySelectable > 0 && newSelected !== null) {
        const cs = this.currentlySelectable;
        this.selectedSCoption[cs] = newSelected.scID;
      }
    },
    asyncFind(query) {
      const q = new RegExp(query, "i");
      function filterSCResults(element) {
        return element.scID.match(q) || element.scDesc.match(q);
      }
      if (query.length > 2) {
        this.socarCapeOptions = this.socarCapeList.filter(filterSCResults);
      }
    },
    socarCapePartLabel({ scDesc, scID }) {
      return `${scDesc} — [${scID}]`;
    },
    loadAJAXData() {
      ApiService.setHeader();
      let urlSuffix = "";
      if (this.asset && parseInt(this.asset) > 0) {
        urlSuffix = "/"+this.asset;
      }
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/parts/list"+urlSuffix)
        .then(data => {
          this.$nextTick(() => {
            this.items = data.data.data;
            this.totalItems = this.items.length;
            this.updateVisibleItems();
          });
          this.dataLoaded = true;
          /*if (this.socarCapeList.length < 1) {
            this.loadSocarCapeData();
          }*/
        })
        .catch(response => {
          //console.log(response);
        });
    },
    loadSocarCapeData() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/socarcape/list"
      )
        .then(data => {
          //console.log(data);
          this.$nextTick(() => {
            this.socarCapeList = data.data.data;
            //console.log(this.socarCapeList);
          });
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
</script>
