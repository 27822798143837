<template>
  <!--begin::Checklists List-->
  <div class="row g-5 gx-xxl-12">
    <div class="col-xxl-12">
      <PartsList
          widget-classes="card-xxl-stretch mb-5 mb-xxl-12"
          asset="All"
      ></PartsList>
    </div>
  </div>
  <!--end::Checklists List-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import PartsList from "@/components/widgets/tables/PartsList.vue";

export default defineComponent({
  name: "supply-chain-warehouse-parts",
  components: {
    PartsList
  },
  methods: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Warehouse");
      setCurrentPageToolbarType("supply-chain-warehouse-parts");
      setCurrentPageBreadcrumbs("Warehouse", ["Supply chain management", "Warehouse management"]);
    });
  },
  data() {
    return {}
  }
});
</script>
