
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import PartsList from "@/components/widgets/tables/PartsList.vue";

export default defineComponent({
  name: "supply-chain-warehouse-parts",
  components: {
    PartsList
  },
  methods: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Warehouse");
      setCurrentPageToolbarType("supply-chain-warehouse-parts");
      setCurrentPageBreadcrumbs("Warehouse", ["Supply chain management", "Warehouse management"]);
    });
  },
  data() {
    return {}
  }
});
