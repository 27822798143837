
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { Field } from "vee-validate";
//import VueMultiselect from "vue-multiselect";
//import "vue-multiselect/dist/vue-multiselect.css";
//import JwPagination from 'jw-vue-pagination';

export default defineComponent({
  name: "PartsList",
  components: { Field }, //VueMultiselect
  props: {
    widgetClasses: String,
    asset: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const items = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const filteredItems = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const visibleItems = [
      {
        id: "",
        p10: "",
        p50: "",
        p90: "",
        currentStock: "",
        description: "",
        predsolID: "",
        oemID: "",
        clientID: "",
        oemName: ""
      }
    ];
    const filters = { keyword: "" };
    const selectedSCoption: Array<string> = [];
    const currentlySelectable = 0;
    const socarCapeList = [];
    const socarCapeOptions = [];
    const title = "Parts list";
    const description = "List of all parts required by units";
    const dataLoaded = false;
    const totalItems = 0;
    const itemsPerPage = 10;
    const currentPage = 1;
    return {
      items,
      title,
      dataLoaded,
      filters,
      selectedSCoption,
      currentlySelectable,
      socarCapeOptions,
      socarCapeList,
      description,
      totalItems,
      currentPage,
      itemsPerPage,
      visibleItems,
      filteredItems
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  methods: {
    updatePartCurrentStock: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        newCurrentStock: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/update-current-stock",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW, and already updated in this table
            //this.loadAJAXData();
            const updateableItem = this.items.find(
              item => item.id === input.target.getAttribute("partID")
            );
            if (updateableItem) {
              updateableItem.currentStock = input.target.value;
              //console.log(updateableItem);
              this.updateVisibleItems();
            }
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    updatePartP10: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        assetID: this.asset,
        newP10: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
          "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p10",
          { data: updateData }
      )
          .then(data => {
            if (data.data.data === "ok") {
              //updated in PW, and already updated in this table
              //this.loadAJAXData();
              const updateableItem = this.items.find(
                  item => item.id === input.target.getAttribute("partID")
              );
              if (updateableItem) {
                updateableItem.p10 = input.target.value;
                //console.log(updateableItem);
                this.updateVisibleItems();
              }
            }
          })
          .catch(response => {
            //console.log(response);
          });
    },
    updatePartP50: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        newP50: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p50",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW, and already updated in this table
            //this.loadAJAXData();
            const updateableItem = this.items.find(
              item => item.id === input.target.getAttribute("partID")
            );
            if (updateableItem) {
              updateableItem.p50 = input.target.value;
              //console.log(updateableItem);
              this.updateVisibleItems();
            }
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    updatePartP90: function(input) {
      const updateData = {
        partID: input.target.getAttribute("partID"),
        assetID: this.asset,
        newP90: input.target.value
      };
      ApiService.setHeader();
      ApiService.post(
          "https://cmms.onecorp.cloud/api/v2/assets/parts/update-part-p90",
          { data: updateData }
      )
          .then(data => {
            if (data.data.data === "ok") {
              //updated in PW, and already updated in this table
              //this.loadAJAXData();
              const updateableItem = this.items.find(
                  item => item.id === input.target.getAttribute("partID")
              );
              if (updateableItem) {
                updateableItem.p90 = input.target.value;
                //console.log(updateableItem);
                this.updateVisibleItems();
              }
            }
          })
          .catch(response => {
            //console.log(response);
          });
    },
    onPageChange: function(currentPage) {
      //this.pageOfItems = pageOfItems;
      //console.log("current page", currentPage);
      this.currentPage = currentPage;
      this.updateVisibleItems();
    },
    onSizeChange: function(itemsPerPage) {
      //console.log("items per page", itemsPerPage);
    },
    updatePartsFilterKeyword: function(selected) {
      this.filters.keyword = selected.target.value;
      this.filteredItems = this.items.filter(
        item =>
          item.predsolID.match(new RegExp(this.filters.keyword, "i")) ||
          item.oemID.match(new RegExp(this.filters.keyword, "i")) ||
          item.clientID.match(new RegExp(this.filters.keyword, "i")) ||
          item.oemName.match(new RegExp(this.filters.keyword, "i")) ||
          item.description.match(new RegExp(this.filters.keyword, "i"))
      );
      this.updateVisibleItems();
    },
    updateVisibleItems: function() {
      //v-if="(filters.keyword === '' || )"
      if (this.filters.keyword === "") {
        this.totalItems = this.items.length;
        this.visibleItems = this.items.slice(
          (this.currentPage - 1) * this.itemsPerPage,
          this.currentPage * this.itemsPerPage
        );
      } else {
        this.totalItems = this.filteredItems.length;
        this.visibleItems = this.filteredItems.slice(
          (this.currentPage - 1) * this.itemsPerPage,
          this.currentPage * this.itemsPerPage
        );
      }
    },
    savePartClientID(id) {
      const updateData = { partID: id, newClientID: this.selectedSCoption[id] };
      ApiService.setHeader();
      ApiService.post(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/socarcape/update",
        { data: updateData }
      )
        .then(data => {
          if (data.data.data === "ok") {
            //updated in PW
            this.loadAJAXData();
            // const updateableItem = this.items.find((item: Object) => item.id === id);
            //updateableItem.clientID = this.selectedSCoption[id];
          }
        })
        .catch(response => {
          //console.log(response);
        });
    },
    startSCselect(id) {
      this.currentlySelectable = id;
    },
    stopSCselect() {
      this.currentlySelectable = 0;
      this.socarCapeOptions = [];
    },
    updateSelectedSCoption(newSelected) {
      if (this.currentlySelectable > 0 && newSelected !== null) {
        const cs = this.currentlySelectable;
        this.selectedSCoption[cs] = newSelected.scID;
      }
    },
    asyncFind(query) {
      const q = new RegExp(query, "i");
      function filterSCResults(element) {
        return element.scID.match(q) || element.scDesc.match(q);
      }
      if (query.length > 2) {
        this.socarCapeOptions = this.socarCapeList.filter(filterSCResults);
      }
    },
    socarCapePartLabel({ scDesc, scID }) {
      return `${scDesc} — [${scID}]`;
    },
    loadAJAXData() {
      ApiService.setHeader();
      let urlSuffix = "";
      if (this.asset && parseInt(this.asset) > 0) {
        urlSuffix = "/"+this.asset;
      }
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/parts/list"+urlSuffix)
        .then(data => {
          this.$nextTick(() => {
            this.items = data.data.data;
            this.totalItems = this.items.length;
            this.updateVisibleItems();
          });
          this.dataLoaded = true;
          /*if (this.socarCapeList.length < 1) {
            this.loadSocarCapeData();
          }*/
        })
        .catch(response => {
          //console.log(response);
        });
    },
    loadSocarCapeData() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/assets/parts/socarcape/list"
      )
        .then(data => {
          //console.log(data);
          this.$nextTick(() => {
            this.socarCapeList = data.data.data;
            //console.log(this.socarCapeList);
          });
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
